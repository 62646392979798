import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M5810 7811 c-46 -94 -82 -172 -79 -175 2 -2 17 -6 34 -8 25 -3 32 3
53 40 22 40 27 43 55 38 16 -3 42 -6 58 -6 25 0 29 -5 41 -47 12 -43 17 -48
46 -51 26 -3 32 0 31 15 0 10 -22 95 -48 188 -47 169 -47 170 -77 173 -28 3
-31 -1 -114 -167z m118 142 c-23 -6 -23 -21 -1 -19 10 0 18 -3 19 -9 0 -5 3
-17 7 -25 6 -16 31 -106 41 -153 5 -19 3 -26 -4 -22 -16 10 -12 -6 5 -19 8 -6
17 -20 19 -31 2 -11 7 -28 11 -37 4 -10 3 -18 -1 -18 -13 0 -36 16 -35 24 3
27 -15 69 -31 73 -10 3 -18 10 -18 15 0 6 6 8 14 5 11 -4 12 2 5 31 -4 20 -6
40 -3 44 3 4 1 8 -4 8 -9 0 -10 4 -16 54 -3 31 -30 54 -42 35 -4 -8 -3 -9 4
-5 8 5 10 2 6 -8 -3 -9 -11 -13 -17 -9 -5 3 -7 1 -4 -4 7 -12 -27 -99 -49
-123 -11 -13 -6 -15 38 -15 34 0 53 -5 56 -13 3 -11 -7 -12 -51 -7 -50 6 -56
5 -62 -14 -3 -12 -10 -21 -15 -21 -5 0 -10 -10 -12 -22 -2 -13 -7 -23 -13 -23
-16 0 -17 32 0 48 8 9 15 19 15 23 0 14 43 99 54 106 6 4 8 8 3 8 -7 0 37 94
55 118 6 6 18 11 27 11 13 -1 13 -2 -1 -6z m6 -160 c8 -33 7 -33 -26 -26 -18
3 -34 7 -36 8 -2 2 7 23 19 46 24 47 27 45 43 -28z"/>
<path d="M5293 7961 c-24 -4 -45 -10 -48 -12 -2 -3 5 -84 18 -180 l23 -174 49
3 c156 10 221 82 195 220 -22 121 -99 167 -237 143z m153 -36 c8 -11 14 -15
14 -9 0 5 9 -6 20 -24 11 -18 18 -37 15 -43 -4 -5 -2 -9 3 -9 11 0 22 -77 14
-97 -2 -7 -9 -10 -16 -6 -6 4 -5 0 2 -9 11 -14 11 -21 -3 -42 -10 -14 -21 -23
-26 -20 -5 3 -8 0 -7 -6 4 -18 -72 -42 -115 -37 -21 3 -35 1 -31 -4 3 -5 0 -9
-7 -9 -7 0 -10 11 -7 30 3 17 2 30 -2 30 -4 0 -7 6 -7 13 0 22 -13 125 -28
221 -5 33 -4 35 27 39 68 10 140 1 154 -18z"/>
<path d="M5333 7923 c-18 -3 -33 -10 -34 -16 -3 -28 1 -65 10 -107 6 -25 10
-65 11 -90 1 -54 22 -89 43 -72 8 7 30 13 49 14 20 2 34 6 32 9 -2 4 3 12 11
19 22 19 35 80 26 127 -5 27 -4 46 3 53 8 8 6 9 -6 5 -12 -5 -18 -2 -18 8 0
31 -66 57 -127 50z m82 -29 c44 -17 66 -155 30 -194 -16 -18 -84 -45 -92 -36
-2 2 -33 218 -33 233 0 6 78 3 95 -3z"/>
<path d="M6310 7884 c-13 -34 -15 -32 28 -44 68 -19 68 -14 19 -163 -47 -142
-47 -147 0 -161 20 -7 24 1 63 126 23 73 46 137 50 142 5 5 28 4 55 -4 40 -11
48 -11 56 1 22 35 10 44 -119 87 -70 23 -131 42 -135 42 -4 0 -12 -12 -17 -26z
m40 -2 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m83 -35 c-3
-6 -1 -7 5 -3 7 4 18 4 25 -1 12 -8 17 -9 50 -11 10 -1 16 -6 13 -11 -4 -5 2
-7 13 -4 10 3 22 0 25 -5 12 -19 -12 -22 -54 -7 -23 8 -44 15 -47 15 -5 0 -27
-54 -24 -63 1 -5 -3 -6 -8 -3 -5 3 -12 -1 -15 -10 -4 -9 -2 -13 5 -8 7 4 9 1
6 -7 -3 -8 -15 -44 -28 -81 -12 -38 -25 -68 -29 -68 -5 0 -6 -9 -3 -20 4 -14
0 -20 -11 -20 -9 0 -16 5 -16 10 0 25 34 129 41 125 4 -3 5 5 1 17 -4 16 -2
19 8 13 8 -5 11 -4 6 3 -3 6 -1 23 6 39 6 15 13 42 15 59 4 29 1 32 -31 38
-24 5 -33 11 -30 20 4 11 14 11 44 4 23 -6 37 -15 33 -21z"/>
<path d="M4863 7851 c-56 -20 -105 -39 -108 -42 -5 -5 74 -241 106 -318 11
-27 16 -26 144 20 75 27 85 34 85 55 0 36 -18 38 -99 9 -40 -15 -74 -25 -75
-23 -5 5 -26 71 -26 79 0 4 30 17 66 29 63 21 65 23 59 49 -4 14 -8 28 -10 30
-2 2 -32 -6 -66 -18 -35 -12 -66 -21 -70 -21 -8 0 -33 69 -27 74 1 2 34 14 73
27 71 25 86 41 63 71 -12 14 -22 12 -115 -21z m101 0 c8 -12 -50 -32 -71 -24
-10 4 -14 2 -9 -5 8 -13 -29 -26 -45 -16 -7 4 -9 3 -6 -3 4 -6 0 -14 -9 -17
-9 -4 -14 -9 -11 -13 4 -3 1 -13 -6 -21 -8 -11 -8 -14 0 -9 6 4 14 3 18 -3 3
-5 -3 -10 -14 -10 -12 0 -21 4 -21 9 0 5 -3 17 -7 27 -5 13 -3 15 8 10 8 -4 5
0 -5 8 -11 9 -15 17 -8 17 15 2 23 4 42 12 102 43 136 52 144 38z m-125 -153
c18 -21 61 -33 61 -16 0 4 7 8 15 8 9 0 29 6 44 14 24 13 28 13 28 0 0 -8 -6
-13 -13 -10 -7 3 -18 -2 -24 -9 -7 -8 -27 -17 -46 -21 -18 -3 -39 -13 -46 -22
-8 -10 -9 -13 -1 -9 9 6 22 -22 28 -63 0 -3 4 -11 8 -17 5 -7 5 -18 1 -25 -4
-7 -3 -8 4 -4 7 4 12 1 12 -6 0 -9 3 -9 10 2 5 8 10 11 10 5 0 -7 3 -7 8 0 7
10 49 28 104 45 21 6 26 5 22 -5 -3 -8 -13 -11 -21 -8 -11 4 -14 2 -9 -5 7
-11 -7 -16 -41 -15 -7 1 -13 -3 -13 -7 0 -11 -60 -31 -72 -24 -6 4 -14 1 -18
-5 -9 -16 -44 75 -36 95 3 8 2 13 -3 10 -10 -7 -43 82 -36 94 3 5 -1 11 -7 13
-10 4 -10 6 0 6 8 1 22 -9 31 -21z"/>
<path d="M4427 7654 c-22 -12 -28 -21 -24 -37 23 -95 48 -239 43 -244 -3 -4
-19 5 -34 19 -15 14 -61 49 -104 78 l-76 52 -31 -18 c-17 -10 -31 -22 -31 -26
0 -18 188 -308 199 -308 3 0 17 9 30 19 l24 18 -52 78 c-28 43 -50 79 -48 81
2 2 39 -21 82 -51 84 -59 92 -62 117 -42 15 11 15 20 -3 111 -11 55 -18 103
-17 108 2 4 23 -26 47 -67 24 -41 49 -79 55 -84 19 -16 59 16 52 41 -7 30
-179 288 -191 287 -5 0 -23 -7 -38 -15z m57 -35 c13 -22 15 -30 5 -25 -8 4 -5
-1 7 -11 12 -10 30 -33 40 -50 10 -18 22 -33 26 -33 4 0 12 -10 19 -21 9 -17
8 -20 -3 -13 -11 6 -11 5 0 -8 8 -11 9 -18 3 -22 -9 -6 -68 73 -62 83 2 2 -1
7 -6 10 -4 3 -18 20 -30 37 -17 25 -19 34 -10 45 10 11 9 11 -4 1 -14 -11 -14
-19 3 -94 10 -46 21 -101 23 -123 3 -22 8 -57 11 -77 7 -45 -8 -50 -46 -15
-14 13 -39 30 -55 38 -16 8 -31 22 -33 32 -5 24 19 21 25 -4 4 -15 8 -17 14
-8 6 11 9 10 15 -4 3 -9 11 -17 16 -17 6 0 17 -8 24 -17 11 -16 12 -12 9 26
-3 24 -7 53 -10 65 -5 17 -4 18 4 6 6 -8 11 -10 11 -4 0 5 -5 15 -11 21 -5 5
-18 50 -29 99 -17 82 -17 89 -2 101 24 17 25 17 46 -18z m-174 -173 c24 -17
48 -34 53 -37 6 -4 6 -13 0 -23 -8 -14 -13 -12 -43 15 -19 17 -34 34 -33 39 1
4 -5 14 -15 22 -16 12 -16 11 -2 -6 14 -18 13 -19 -2 -13 -26 9 -30 -8 -8 -28
11 -10 17 -22 14 -26 -3 -5 0 -9 5 -9 6 0 11 -6 11 -14 0 -7 7 -19 15 -26 21
-17 62 -76 48 -67 -7 4 -15 2 -19 -5 -4 -7 -4 -10 1 -6 13 12 47 -12 41 -28
-3 -9 -1 -12 4 -9 6 3 10 -1 10 -9 0 -25 -29 -18 -36 8 -3 14 -12 27 -20 30
-8 3 -11 12 -7 22 4 11 3 15 -5 10 -12 -7 -38 32 -33 53 1 8 -2 10 -10 5 -9
-5 -10 -2 -6 9 4 12 2 14 -11 10 -9 -3 -14 -3 -10 2 4 4 0 14 -8 22 -8 8 -12
19 -9 24 4 5 2 9 -2 9 -5 0 -16 13 -25 28 -16 26 -16 29 0 39 19 12 33 7 102
-41z m307 -25 c10 -16 9 -22 -3 -32 -13 -10 -17 -10 -25 2 -10 17 -11 33 -2
42 10 11 17 8 30 -12z m23 -45 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25
14 25 6z"/>
<path d="M4440 7620 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4216 7482 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"/>
<path d="M6773 7544 c-79 -63 -143 -118 -143 -123 0 -18 39 -43 59 -36 12 3
21 10 21 15 0 4 11 15 25 24 24 16 27 15 74 -16 l50 -33 -6 -50 c-5 -47 -3
-51 21 -64 14 -8 28 -12 32 -9 3 3 19 90 36 193 31 182 31 188 13 201 -10 8
-24 14 -29 13 -6 0 -75 -52 -153 -115z m152 86 c-4 -6 1 -7 11 -3 15 5 16 2
10 -28 -3 -19 -14 -80 -22 -136 -9 -57 -19 -101 -23 -99 -3 2 -4 -16 -2 -40 2
-36 0 -44 -14 -44 -15 0 -17 7 -11 49 6 43 4 53 -17 75 -14 14 -31 26 -38 26
-8 0 -25 7 -38 16 -22 16 -25 16 -52 0 -16 -10 -33 -14 -39 -11 -6 3 -7 -1 -4
-9 6 -17 -20 -23 -30 -7 -6 9 112 97 126 93 5 -1 7 1 4 6 -8 13 76 78 94 73 8
-1 12 0 8 4 -3 4 1 15 10 26 17 20 38 27 27 9z"/>
<path d="M6910 7593 c-14 -6 -65 -45 -101 -78 -9 -8 -23 -15 -32 -15 -9 0 -19
-5 -23 -12 -4 -7 -3 -8 5 -4 13 8 44 -18 33 -28 -3 -4 1 -4 11 0 11 4 17 1 17
-7 0 -7 3 -10 6 -6 4 3 13 0 21 -8 22 -22 43 -18 43 8 0 13 3 17 8 10 4 -6 8
15 9 46 0 32 6 68 12 80 7 11 10 21 9 20 -2 0 -10 -3 -18 -6z m-26 -90 c-3
-27 -8 -49 -9 -51 -2 -2 -18 4 -36 14 l-33 17 39 33 c22 19 41 34 43 34 2 0 0
-21 -4 -47z"/>
<path d="M6839 7563 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M7229 7331 c-38 -39 -38 -76 2 -163 26 -56 30 -70 19 -83 -19 -22
-48 -18 -77 11 -21 21 -24 32 -19 62 4 26 1 41 -11 54 -22 25 -30 23 -48 -12
-20 -39 -19 -63 5 -110 25 -49 71 -81 124 -87 34 -4 47 -1 70 18 43 38 45 87
6 163 -36 73 -36 100 0 104 47 5 85 -49 60 -88 -6 -10 -3 -21 12 -37 28 -30
33 -29 53 12 20 44 13 84 -22 126 -55 65 -126 77 -174 30z m35 -3 c-5 -7 -3
-8 7 -3 42 26 112 -6 127 -58 19 -65 10 -109 -18 -92 -7 4 -6 13 1 27 16 30 3
64 -35 93 -34 26 -36 26 -69 10 -32 -15 -34 -19 -30 -52 3 -20 14 -49 25 -64
12 -16 17 -32 13 -40 -6 -8 -4 -10 5 -4 10 6 12 3 8 -13 -4 -12 -2 -22 3 -22
14 0 11 -28 -7 -55 -10 -15 -21 -22 -31 -18 -11 4 -14 2 -9 -5 5 -8 -1 -12
-18 -12 -14 0 -28 5 -31 10 -4 6 -11 8 -16 5 -5 -4 -9 -1 -9 5 0 6 -4 9 -9 6
-9 -6 -43 28 -61 63 -20 38 3 116 25 82 3 -5 -2 -16 -10 -25 -10 -10 -12 -17
-4 -21 6 -4 8 -13 6 -19 -3 -7 4 -18 15 -25 12 -7 17 -18 14 -28 -4 -11 -2
-14 9 -10 8 4 17 2 20 -3 9 -14 59 -12 74 3 11 10 12 9 6 -3 -5 -8 -4 -12 0
-8 17 16 13 62 -10 115 -14 30 -22 60 -18 66 3 5 1 7 -4 4 -6 -4 -13 4 -16 18
-2 14 0 28 5 31 4 3 6 9 3 14 -3 5 5 16 17 24 27 19 32 20 22 4z"/>
<path d="M7365 7290 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M7135 7080 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5698 7263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5810 7260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3917 7242 c-9 -10 -17 -23 -17 -28 0 -16 234 -244 250 -244 19 0 40
19 40 37 0 11 -231 244 -249 251 -4 1 -15 -6 -24 -16z m69 -44 c23 -23 41 -45
38 -50 -3 -5 0 -7 8 -6 8 2 12 -4 10 -16 -2 -11 1 -15 8 -11 14 9 72 -44 63
-58 -3 -6 -2 -7 4 -4 6 4 21 -5 33 -18 19 -20 21 -28 12 -37 -10 -10 -20 -4
-47 25 -20 21 -33 43 -30 48 3 5 0 6 -7 4 -17 -7 -89 68 -91 94 0 11 -5 17 -9
15 -5 -3 -5 -11 -2 -17 3 -7 -8 3 -25 21 -18 17 -29 37 -26 42 10 16 15 13 61
-32z"/>
<path d="M3930 7226 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4055 7101 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z"/>
<path d="M5518 7253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5868 7253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5930 7250 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5468 7243 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5370 7236 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z"/>
<path d="M6108 7213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5238 7203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6167 7199 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5170 7180 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M6210 7180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6240 7180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5103 7163 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"/>
<path d="M6280 7160 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M5170 7140 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M6325 7140 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M6435 7100 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6505 7065 c22 -14 45 -25 50 -25 6 0 -10 11 -34 25 -56 32 -68 32
-16 0z"/>
<path d="M4884 7064 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
<path d="M4773 7000 c-18 -11 -33 -22 -33 -26 0 -4 60 28 79 43 16 12 -20 -1
-46 -17z"/>
<path d="M7537 6941 c-142 -100 -158 -129 -117 -218 23 -51 70 -83 122 -83 31
0 60 15 150 76 62 42 115 78 117 80 6 6 -29 54 -40 54 -6 0 -55 -32 -111 -71
-62 -44 -110 -71 -126 -71 -50 0 -81 72 -46 107 9 9 56 43 105 75 48 33 90 64
94 70 7 11 -20 61 -33 59 -4 0 -56 -35 -115 -78z m128 39 c8 -13 -41 -43 -57
-34 -7 4 -10 4 -6 -1 11 -11 -33 -47 -49 -40 -8 2 -12 1 -9 -4 3 -4 -3 -16
-14 -26 -11 -10 -20 -14 -20 -10 0 5 -6 1 -13 -10 -8 -10 -19 -15 -25 -11 -6
3 -14 1 -18 -5 -5 -8 -3 -9 6 -4 8 5 11 3 8 -6 -3 -8 -10 -13 -15 -12 -13 3
-7 -51 8 -80 26 -48 75 -61 117 -31 13 9 26 13 29 10 3 -3 15 6 26 20 10 14
26 23 33 20 8 -3 13 0 11 7 -1 7 4 11 11 10 6 -2 12 1 12 6 0 14 69 51 75 40
8 -13 -3 -29 -21 -29 -8 0 -14 -5 -14 -12 0 -6 -3 -9 -6 -5 -4 3 -14 -2 -23
-13 -9 -11 -22 -20 -30 -22 -8 -2 -22 -10 -32 -18 -40 -34 -49 -40 -59 -40 -5
0 -10 -4 -10 -8 0 -9 -35 -12 -68 -6 -14 3 -20 9 -16 16 5 7 2 9 -8 5 -30 -11
-71 60 -64 111 4 33 42 85 56 77 5 -3 11 2 14 11 4 10 12 14 23 10 10 -4 14
-4 11 0 -10 9 39 47 51 40 5 -3 11 4 14 15 3 12 10 18 15 15 6 -3 12 1 16 9 6
17 32 20 41 5z"/>
<path d="M6633 6985 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z"/>
<path d="M3682 6955 c-27 -19 -112 -134 -112 -150 0 -5 66 -55 147 -109 l146
-99 19 23 c10 12 18 26 18 30 0 4 -24 24 -54 45 -54 37 -54 38 -39 61 15 23
19 24 82 18 36 -4 72 -8 80 -8 16 -1 49 49 40 59 -4 3 -31 8 -60 11 -91 9 -99
12 -99 39 0 14 -13 39 -28 56 -34 39 -103 50 -140 24z m99 -20 c19 -8 30 -14
25 -15 -6 0 -2 -6 9 -14 14 -10 17 -21 13 -47 -6 -31 -4 -34 21 -35 66 -2 107
-9 113 -18 5 -7 8 -7 8 2 0 6 5 12 10 12 6 0 5 -8 -2 -19 -8 -13 -17 -17 -26
-12 -8 5 -24 6 -35 3 -11 -3 -18 -1 -14 4 3 6 -15 8 -49 4 -57 -6 -74 1 -55
24 8 10 8 21 0 42 -7 16 -12 32 -11 37 1 4 -1 6 -5 6 -19 -3 -27 2 -15 10 11
7 10 9 -3 14 -10 5 -14 3 -9 -4 5 -8 -4 -10 -29 -7 -20 2 -35 1 -34 -2 4 -11
-60 -90 -67 -83 -4 4 -4 0 0 -10 3 -9 10 -17 16 -17 5 0 6 -4 3 -10 -4 -6 1
-7 11 -3 12 4 15 3 10 -6 -4 -6 -4 -10 1 -9 11 4 54 -28 47 -35 -3 -3 3 -3 15
0 11 3 27 16 36 30 13 19 20 22 28 13 9 -9 5 -17 -16 -36 -15 -13 -23 -24 -18
-24 6 0 20 -9 30 -19 11 -11 29 -25 41 -31 11 -6 18 -15 15 -20 -3 -6 0 -7 8
-4 8 3 14 -1 14 -9 0 -15 -9 -16 -39 -2 -10 4 -15 11 -12 16 3 5 -1 9 -8 9
-21 0 -123 70 -120 81 1 6 -2 8 -7 5 -5 -4 -16 1 -24 9 -9 8 -20 15 -26 15 -6
0 -11 5 -11 10 0 6 -7 10 -15 10 -8 0 -15 4 -15 8 0 11 45 71 67 89 10 8 12
11 4 7 -10 -5 -12 -3 -6 5 8 13 62 40 81 41 7 0 27 -7 45 -15z m-13 -47 c18
-18 14 -57 -9 -89 -20 -29 -21 -29 -51 -12 -50 30 -53 39 -24 78 28 36 62 45
84 23z"/>
<path d="M3766 6763 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M6693 6950 c2 -8 12 -16 23 -17 13 -2 15 -1 4 4 -8 3 -19 11 -23 17
-6 7 -7 6 -4 -4z"/>
<path d="M4646 6909 c-21 -16 -35 -29 -30 -29 5 0 27 14 48 30 22 17 35 30 30
29 -5 0 -27 -14 -48 -30z"/>
<path d="M4584 6855 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M6800 6867 c0 -2 14 -16 30 -32 17 -16 30 -24 30 -19 0 6 -13 20 -30
32 -16 13 -30 21 -30 19z"/>
<path d="M4529 6803 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M6880 6795 c6 -8 9 -18 7 -22 -3 -5 3 -9 13 -9 18 0 18 2 -2 23 -24
25 -38 32 -18 8z"/>
<path d="M4469 6742 l-34 -37 35 30 c19 17 37 31 39 33 2 1 2 4 -1 7 -2 3 -20
-12 -39 -33z"/>
<path d="M6980 6692 c0 -5 10 -17 22 -28 l21 -19 -18 28 c-18 28 -25 33 -25
19z"/>
<path d="M4381 6647 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M4331 6586 l-24 -31 28 27 c16 15 27 29 24 31 -2 3 -15 -10 -28 -27z"/>
<path d="M7065 6580 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7090 6546 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M4281 6518 c-14 -23 -14 -23 8 -4 22 20 25 26 13 26 -4 0 -13 -10
-21 -22z"/>
<path d="M7780 6475 c-95 -39 -156 -69 -158 -78 -5 -25 63 -162 89 -180 13
-10 37 -17 54 -17 37 0 91 32 99 58 5 14 13 18 31 14 64 -12 115 28 115 91 -1
44 -47 169 -65 173 -5 1 -80 -26 -165 -61z m155 27 c21 -15 45 -72 51 -120 5
-46 3 -51 -23 -70 -24 -17 -64 -22 -83 -12 -7 4 -22 7 -39 9 -7 0 -11 7 -8 15
5 14 -37 116 -48 116 -3 0 -4 -4 -1 -9 8 -12 -20 -22 -41 -14 -10 4 -14 2 -10
-4 4 -6 -7 -16 -26 -24 -17 -7 -33 -13 -35 -14 -1 -1 5 -19 13 -39 9 -20 13
-41 10 -46 -4 -6 0 -10 8 -10 19 0 40 -26 32 -40 -4 -7 -16 -1 -31 15 -13 15
-22 31 -19 36 4 5 2 9 -4 9 -6 0 -7 7 -4 17 5 12 3 14 -7 8 -10 -6 -12 -4 -7
8 3 10 3 17 -2 17 -5 0 -11 11 -13 24 -4 20 1 27 31 40 20 8 43 17 51 18 8 2
17 6 20 10 3 4 17 9 30 10 14 2 27 6 30 10 5 6 98 46 110 47 3 1 10 -3 15 -7z
m-121 -179 c5 -17 1 -26 -18 -38 -35 -23 -60 -12 -81 35 -10 22 -16 42 -14 43
2 2 22 11 44 20 l40 15 11 -26 c6 -15 15 -37 18 -49z m36 -45 c0 -21 -50 -57
-75 -55 -33 3 -32 20 3 31 15 5 33 18 40 28 13 18 32 16 32 -4z"/>
<path d="M7898 6470 c-9 -6 -28 -11 -41 -12 -14 -1 -24 -5 -21 -9 2 -4 -1 -9
-8 -11 -8 -3 -7 -17 5 -52 9 -27 15 -52 12 -56 -2 -3 2 -7 10 -7 8 0 12 5 9
11 -3 6 0 4 8 -4 13 -16 45 -15 63 1 12 12 28 11 20 -1 -3 -5 -2 -10 4 -10 14
0 6 79 -13 123 -17 39 -22 42 -48 27z m41 -63 c11 -30 11 -40 1 -52 -26 -32
-72 -8 -85 45 -5 22 -2 28 22 37 39 15 46 12 62 -30z"/>
<path d="M3450 6512 c-27 -13 -43 -33 -68 -83 -61 -125 -67 -114 115 -187 87
-34 163 -62 169 -62 13 0 24 22 24 46 0 9 -24 26 -55 39 -30 14 -55 26 -55 29
0 2 7 23 15 46 31 88 13 149 -51 176 -43 18 -52 18 -94 -4z m87 -14 c6 -1 9
-7 6 -15 -3 -7 -1 -13 5 -13 6 0 12 6 15 13 3 6 12 -4 20 -23 9 -19 12 -36 7
-38 -4 -2 -6 -11 -3 -22 3 -10 -2 -33 -11 -51 -9 -18 -16 -38 -16 -45 0 -7 -8
-18 -17 -24 -16 -9 -15 -10 4 -5 19 5 22 3 16 -12 -6 -16 -5 -17 12 -3 17 14
18 13 12 -4 -6 -14 -5 -16 5 -8 9 7 22 6 46 -3 38 -17 43 -37 6 -31 -42 8 -94
27 -94 35 0 4 -6 8 -14 8 -27 0 -76 25 -69 36 4 6 0 8 -11 4 -16 -6 -96 19
-96 31 0 6 30 74 57 128 18 38 49 55 83 48 14 -3 30 -5 37 -6z"/>
<path d="M3518 6484 c-3 -3 -20 -6 -38 -6 -19 -1 -34 -7 -38 -17 -3 -9 -12
-22 -19 -29 -7 -8 -11 -18 -8 -23 4 -5 3 -9 -2 -9 -4 0 -9 -13 -10 -30 -2 -22
4 -33 20 -42 12 -7 26 -11 30 -10 4 1 21 -5 38 -14 17 -8 32 -14 34 -12 9 8
48 105 50 121 1 10 -3 20 -8 24 -5 3 -3 10 4 15 11 8 11 9 -1 5 -20 -6 -52 11
-45 24 7 10 2 12 -7 3z m21 -49 c12 -12 12 -21 1 -60 -7 -25 -17 -48 -22 -51
-5 -4 -30 2 -54 11 -38 15 -44 20 -38 38 11 38 33 75 46 80 17 7 50 -2 67 -18z"/>
<path d="M5176 6503 c-4 -11 -1 -11 14 -3 24 13 25 17 5 17 -8 0 -16 -6 -19
-14z"/>
<path d="M4261 6474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7078 6483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6055 6440 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7160 6445 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6350 6430 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4215 6409 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M6380 6411 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6004 6396 c-10 -8 -16 -17 -13 -20 3 -3 14 3 24 14 21 23 17 26 -11
6z"/>
<path d="M5330 6380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5077 6362 c-10 -10 -17 -22 -17 -27 0 -5 9 1 21 14 12 12 19 24 17
27 -3 2 -12 -4 -21 -14z"/>
<path d="M5971 6344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4205 6340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6431 6334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7211 6327 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M5350 6326 c0 -4 9 -19 20 -33 11 -14 20 -21 20 -16 0 6 -9 20 -20
33 -11 13 -20 20 -20 16z"/>
<path d="M5400 6265 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M4136 6253 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4996 6245 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M5436 6205 c4 -11 16 -27 26 -36 10 -9 14 -20 10 -24 -4 -5 -1 -5 6
-1 11 6 8 14 -10 35 -12 14 -26 31 -31 36 -4 6 -5 1 -1 -10z"/>
<path d="M4118 6143 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M4915 6128 l-16 -33 20 25 c20 24 26 40 16 40 -2 0 -12 -15 -20 -32z"/>
<path d="M4061 6034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5550 6042 c0 -5 10 -17 21 -28 22 -19 22 -19 10 3 -12 24 -31 39
-31 25z"/>
<path d="M4051 5984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4807 5970 c-10 -16 -22 -30 -26 -30 -5 0 -12 -7 -15 -16 -9 -23 9
-9 44 36 17 22 28 40 23 40 -5 0 -17 -13 -26 -30z"/>
<path d="M5595 5970 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5620 5954 c1 -15 28 -46 33 -37 2 4 -4 17 -14 27 -11 11 -19 15 -19
10z"/>
<path d="M4027 5893 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5660 5895 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M4721 5854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4022 5840 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7376 5837 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4744 5781 c5 -8 56 -3 56 5 0 2 -14 4 -31 4 -16 0 -28 -4 -25 -9z"/>
<path d="M4005 5759 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M4002 5710 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3524 5696 c-130 -57 -93 -256 48 -256 84 0 138 51 138 130 0 54 -26
97 -72 121 -43 22 -71 23 -114 5z m101 -26 c-3 -5 0 -7 8 -4 25 9 57 -43 57
-92 0 -86 -81 -135 -165 -100 -21 9 -35 19 -32 22 3 3 -3 15 -14 27 -10 11
-19 29 -19 38 0 29 11 49 24 42 6 -4 5 0 -2 9 -11 14 -9 20 15 42 23 21 38 26
81 26 32 0 51 -4 47 -10z"/>
<path d="M3637 5503 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7771 5657 c-60 -31 -82 -116 -47 -184 17 -35 70 -63 116 -63 79 0
131 54 133 137 1 76 -50 123 -133 123 -25 0 -56 -6 -69 -13z m154 -39 c25 -27
32 -75 19 -123 -7 -27 -42 -51 -84 -59 -26 -5 -31 -4 -20 3 11 8 5 9 -21 5
-30 -5 -39 -2 -51 15 -8 11 -18 18 -22 14 -4 -4 -4 1 0 11 5 13 4 17 -3 13
-10 -6 -14 12 -15 71 0 13 13 35 29 51 25 24 37 27 86 26 49 -1 61 -5 82 -27z"/>
<path d="M7822 5631 c-11 -7 -11 -9 0 -14 9 -3 15 1 15 9 0 8 -1 14 -1 14 -1
0 -7 -4 -14 -9z"/>
<path d="M3991 5554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7398 5533 c-2 -5 -1 -30 3 -58 l6 -50 1 58 c2 52 -2 69 -10 50z"/>
<path d="M6700 5389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7393 5360 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6651 5319 c-21 -28 -21 -30 -3 -20 11 6 22 20 25 31 7 26 5 26 -22
-11z"/>
<path d="M4021 5284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5780 5286 c0 -15 44 -76 54 -76 5 0 0 10 -11 23 -11 12 -25 31 -31
42 -6 11 -12 16 -12 11z"/>
<path d="M6593 5238 c-15 -20 -25 -38 -20 -38 3 0 12 11 21 25 17 26 16 35 -1
13z"/>
<path d="M6511 5125 c-18 -25 -31 -45 -29 -45 10 0 76 80 69 84 -4 3 -23 -15
-40 -39z"/>
<path d="M5860 5130 c0 -5 6 -7 14 -4 8 3 16 -3 19 -15 3 -12 11 -21 19 -21 8
0 9 3 2 8 -6 4 -13 15 -17 25 -7 18 -37 24 -37 7z"/>
<path d="M7326 5098 c-8 -33 -7 -48 3 -48 5 0 7 10 3 22 -3 13 -1 19 5 15 7
-5 9 0 6 13 -7 26 -9 25 -17 -2z"/>
<path d="M6440 5028 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"/>
<path d="M5964 5015 c8 -8 18 -15 22 -15 3 0 1 7 -6 15 -7 8 -17 15 -22 15 -5
0 -3 -7 6 -15z"/>
<path d="M4080 5013 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M4121 4994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6449 4993 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M7306 4995 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M6404 4979 c-19 -22 -19 -22 4 -8 12 8 22 17 22 21 0 12 -6 9 -26
-13z"/>
<path d="M7680 4924 c-10 -26 -6 -44 10 -44 4 0 29 -9 54 -19 l45 -20 -20 -57
c-24 -67 -19 -111 15 -143 47 -44 115 -44 162 0 29 27 89 168 77 180 -12 12
-303 129 -320 129 -8 0 -18 -12 -23 -26z m86 -18 c32 -13 73 -31 89 -40 17 -9
36 -16 43 -16 6 0 12 -4 12 -10 0 -5 3 -9 8 -8 4 1 21 -2 39 -6 36 -8 52 -39
33 -61 -7 -8 -9 -15 -6 -15 4 0 2 -9 -4 -20 -6 -11 -15 -18 -20 -15 -4 3 -6
-3 -3 -13 5 -20 -20 -53 -54 -70 -22 -12 -83 -6 -83 9 0 4 -9 11 -20 14 -11 4
-18 11 -14 16 3 6 1 16 -6 23 -10 13 -6 57 9 81 3 6 6 16 8 24 1 8 5 17 10 20
11 8 10 48 -1 58 -5 4 -6 2 -2 -4 9 -17 7 -17 -57 8 -38 15 -54 26 -50 35 6
17 -1 18 69 -10z"/>
<path d="M7837 4860 c10 -10 8 -19 -8 -42 -11 -15 -17 -28 -14 -28 4 0 0 -11
-8 -24 -17 -26 -13 -61 11 -89 15 -16 14 -17 -4 -11 -10 4 -16 4 -12 0 12 -14
92 -12 116 2 12 8 22 22 22 32 0 10 6 25 13 32 6 7 13 27 13 44 1 25 -2 31
-16 27 -10 -3 -21 0 -24 6 -5 7 0 9 11 5 13 -4 14 -3 5 4 -7 5 -25 7 -39 4
-18 -3 -24 -1 -19 6 5 8 1 10 -10 5 -10 -4 -15 -2 -11 3 3 6 -4 16 -16 23 -17
10 -19 10 -10 1z m97 -118 c-21 -50 -33 -62 -63 -62 -51 0 -64 37 -36 103 l15
36 50 -21 49 -20 -15 -36z"/>
<path d="M7870 4640 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6355 4909 c-22 -42 -15 -44 12 -3 12 19 18 34 13 34 -5 0 -17 -14
-25 -31z"/>
<path d="M7281 4924 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3544 4918 c-12 -5 -27 -21 -35 -34 -10 -19 -20 -24 -54 -24 -51 0
-91 -28 -100 -69 -6 -28 32 -159 55 -186 8 -10 45 1 173 52 89 36 164 66 166
68 9 9 -57 151 -82 176 -31 31 -81 38 -123 17z m86 -19 c0 -5 4 -8 9 -5 4 3
16 -3 26 -14 18 -19 17 -20 -4 -20 -12 0 -19 4 -16 10 3 6 -1 7 -9 4 -9 -3
-16 -2 -16 4 0 11 -38 4 -57 -11 -9 -6 -13 -6 -13 2 0 7 -5 9 -12 5 -9 -6 -9
-9 0 -12 9 -3 9 -9 1 -23 -5 -11 -14 -17 -20 -14 -14 10 -11 21 17 54 19 24
34 31 60 31 19 0 34 -5 34 -11z m2 -56 c8 -10 19 -30 26 -45 11 -27 10 -29
-24 -43 -51 -21 -58 -19 -72 18 -16 46 -15 64 6 76 26 15 47 13 64 -6z m71
-39 c9 -25 14 -51 10 -57 -3 -6 -1 -7 5 -3 7 4 12 2 12 -3 0 -6 -2 -11 -3 -11
-2 0 -40 -13 -85 -29 -44 -16 -83 -27 -86 -23 -3 3 -6 1 -6 -5 0 -22 -129 -61
-131 -40 -3 27 -5 33 -18 57 -27 48 -30 86 -9 121 16 25 22 29 29 18 5 -8 9
-9 9 -4 0 12 34 13 68 1 24 -8 57 -61 47 -76 -3 -6 -1 -10 4 -10 6 0 11 -7 11
-15 0 -20 18 -19 78 6 30 12 52 29 55 40 3 10 1 17 -4 13 -8 -5 -10 0 -13 39
-1 6 -5 15 -11 19 -6 5 -4 8 5 8 10 0 23 -19 33 -46z"/>
<path d="M3413 4786 c-15 -33 -15 -38 1 -80 20 -52 38 -57 96 -28 34 17 45 40
26 54 -6 4 -11 19 -12 35 0 15 -5 28 -10 28 -5 0 -13 3 -19 6 -5 4 -22 9 -38
13 -24 6 -29 2 -44 -28z m63 6 c11 -7 44 -65 44 -77 0 -7 -71 -38 -74 -33 -28
46 -32 93 -8 107 21 12 24 13 38 3z"/>
<path d="M3665 4730 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M7260 4865 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M4140 4860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5002 4820 c7 -10 14 -18 15 -16 6 6 -9 36 -18 36 -5 0 -4 -9 3 -20z"/>
<path d="M5412 4825 c-7 -8 -9 -15 -4 -15 5 0 15 7 22 15 16 19 -1 19 -18 0z"/>
<path d="M6291 4818 c-14 -23 -14 -23 8 -4 22 20 25 26 13 26 -4 0 -13 -10
-21 -22z"/>
<path d="M7236 4825 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M5380 4789 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7216 4787 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4180 4785 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6130 4781 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6255 4773 c-11 -8 -27 -18 -35 -23 -8 -5 -12 -12 -8 -15 4 -4 13 -2
20 4 7 7 22 18 33 26 11 7 18 16 15 18 -3 3 -14 -1 -25 -10z"/>
<path d="M4210 4725 c0 -4 11 -23 25 -41 14 -18 25 -37 25 -43 0 -5 4 -13 10
-16 6 -4 7 1 3 12 -9 23 -63 99 -63 88z"/>
<path d="M7080 4572 c0 -10 -5 -23 -12 -30 -7 -7 -8 -12 -4 -12 12 0 35 32 36
48 0 6 -4 12 -10 12 -5 0 -10 -8 -10 -18z"/>
<path d="M4331 4533 c13 -16 25 -30 27 -32 1 -2 4 -2 7 0 5 6 -40 59 -50 59
-5 0 2 -12 16 -27z"/>
<path d="M7482 4510 c-19 -30 -16 -37 36 -71 26 -18 49 -34 51 -35 2 -2 -4
-15 -13 -29 -12 -18 -23 -24 -39 -21 -12 3 -47 7 -77 10 -54 5 -57 5 -74 -24
-21 -36 -19 -37 79 -47 68 -8 69 -9 76 -40 8 -39 23 -60 60 -79 63 -32 109 -9
174 85 27 39 47 73 44 75 -20 18 -289 196 -296 196 -4 0 -14 -9 -21 -20z m43
-22 c-4 8 21 -5 54 -28 43 -30 60 -47 56 -58 -4 -11 -2 -12 7 -5 10 8 30 -1
75 -31 55 -38 61 -45 50 -60 -12 -16 -14 -16 -31 3 -10 11 -15 24 -12 30 4 5
1 13 -5 17 -8 5 -9 3 -4 -6 6 -10 4 -12 -9 -7 -10 4 -22 7 -27 7 -6 0 -7 5 -3
12 4 7 3 8 -4 4 -7 -4 -12 -1 -12 7 0 8 -9 13 -22 11 -13 -1 -30 -3 -38 -3
-12 -1 -12 -3 -2 -10 15 -9 2 -22 -31 -34 -14 -5 -12 1 9 28 14 19 31 32 38
28 6 -3 8 -3 4 2 -4 4 -18 13 -30 20 -14 7 -22 18 -20 31 2 16 1 16 -7 4 -9
-13 -12 -12 -26 5 -9 11 -13 13 -9 5 5 -10 1 -9 -14 3 -11 11 -18 25 -15 33 4
12 8 11 20 -3 8 -10 11 -12 8 -5z m147 -154 l38 -27 -19 -31 c-11 -17 -32 -35
-46 -41 -23 -8 -30 -6 -46 14 -21 25 -18 47 9 89 18 28 18 28 64 -4z m-202 0
c97 -3 108 -6 102 -26 -2 -10 -9 -18 -14 -18 -7 0 -7 -5 -1 -12 12 -17 11 -47
-2 -50 -16 -4 -19 64 -3 74 8 5 3 6 -14 2 -16 -3 -28 -2 -28 2 0 5 -22 8 -50
7 -27 -1 -52 3 -56 8 -3 5 -11 7 -17 3 -7 -3 -3 2 7 10 11 9 22 13 25 8 3 -4
26 -8 51 -8z m267 -55 c-2 -11 -9 -24 -16 -28 -7 -5 -12 -12 -12 -17 1 -5 -5
-9 -11 -9 -7 -1 -12 -6 -11 -12 1 -5 -9 -16 -23 -23 -30 -17 -99 -3 -99 20 0
8 7 13 15 11 8 -2 27 -6 42 -9 15 -2 25 -8 22 -13 -3 -5 2 -6 10 -3 9 3 13 10
10 15 -3 5 -1 9 4 9 5 0 21 18 36 40 30 45 37 50 33 19z"/>
<path d="M3785 4486 c-41 -18 -225 -145 -225 -155 0 -5 8 -19 19 -32 l19 -23
73 52 c133 94 153 104 182 91 29 -13 50 -53 42 -80 -4 -10 -56 -53 -116 -95
-110 -76 -113 -80 -81 -116 13 -16 22 -12 111 49 132 90 151 112 151 169 0 61
-29 107 -84 133 -49 24 -51 24 -91 7z m73 -19 c11 5 13 3 7 -7 -6 -10 -4 -12
7 -7 17 6 46 -22 65 -65 13 -29 7 -92 -8 -82 -5 3 -6 -2 -2 -12 5 -13 2 -15
-14 -10 -13 4 -25 0 -34 -11 -13 -16 -12 -17 4 -4 25 20 31 8 7 -14 -17 -15
-22 -16 -37 -4 -16 13 -17 12 -5 -3 14 -18 0 -34 -20 -22 -7 5 -8 3 -3 -6 6
-10 4 -12 -9 -7 -11 4 -15 3 -10 -4 10 -16 -18 -30 -33 -17 -10 8 -12 7 -7 -5
4 -11 -2 -20 -20 -28 -14 -7 -26 -17 -26 -23 0 -10 -20 13 -20 24 0 13 70 51
81 44 7 -4 9 -3 5 4 -4 6 21 30 58 57 67 48 100 100 74 117 -7 4 -9 8 -4 8 5
0 -7 14 -25 30 -26 23 -40 29 -59 24 -14 -4 -33 -6 -42 -6 -10 0 -17 -4 -15
-9 1 -6 -15 -20 -37 -32 -21 -12 -50 -32 -64 -45 -14 -12 -32 -20 -39 -17 -8
2 -12 1 -9 -4 3 -5 -2 -15 -11 -22 -12 -11 -17 -11 -20 -2 -6 16 -5 23 5 24 4
1 13 10 21 21 7 11 20 17 28 14 8 -3 12 -2 9 3 -5 8 89 73 99 68 2 -1 10 5 17
15 15 19 49 28 62 17 5 -4 15 -5 24 -2z"/>
<path d="M3747 4426 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M4381 4473 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M6992 4449 c-13 -16 -20 -29 -15 -28 4 0 18 13 31 30 13 16 20 29 15
28 -4 0 -18 -13 -31 -30z"/>
<path d="M4459 4386 c14 -14 25 -26 26 -26 0 0 1 9 1 20 0 10 -2 17 -4 15 -3
-2 -14 1 -26 7 -19 10 -19 8 3 -16z"/>
<path d="M6904 4358 c-29 -31 -29 -31 -2 -12 15 10 29 25 32 32 8 18 2 15 -30
-20z"/>
<path d="M4535 4310 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M6819 4273 l-34 -36 37 29 c21 16 38 32 38 37 0 12 -5 9 -41 -30z"/>
<path d="M4586 4265 c10 -8 23 -15 28 -15 6 0 1 7 -10 15 -10 8 -23 15 -28 15
-6 0 -1 -7 10 -15z"/>
<path d="M6749 4213 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M6696 4181 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M4710 4171 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M7190 4145 l-24 -26 129 -129 130 -130 24 25 25 24 -52 55 c-58 61
-120 123 -174 174 l-34 32 -24 -25z m62 -33 c10 -8 15 -19 12 -24 -3 -4 0 -7
7 -6 7 2 18 -8 26 -22 7 -14 13 -21 13 -16 0 5 11 -1 25 -14 14 -13 24 -29 22
-35 -2 -5 5 -19 16 -30 11 -11 16 -13 12 -5 -4 8 8 -1 29 -19 22 -22 32 -39
27 -44 -15 -15 -38 -1 -69 42 -16 22 -31 39 -33 38 -3 -1 -1 2 3 8 5 5 8 14 8
20 0 5 -5 3 -10 -5 -7 -12 -22 -2 -73 50 -36 35 -62 70 -60 77 5 14 17 10 45
-15z"/>
<path d="M6574 4126 c11 -9 24 -16 30 -16 12 0 7 5 -24 19 -24 11 -24 11 -6
-3z"/>
<path d="M4066 4099 c-36 -42 -34 -92 6 -173 27 -54 30 -67 19 -80 -18 -21
-33 -20 -66 5 -24 20 -26 26 -20 65 5 36 3 44 -14 53 -29 16 -37 14 -49 -14
-38 -82 35 -188 129 -188 36 0 48 6 72 33 25 28 28 38 23 72 -4 22 -18 61 -31
86 -29 55 -32 87 -8 101 43 24 108 -53 83 -99 -9 -18 -8 -25 6 -41 36 -39 64
-12 64 63 0 80 -70 148 -153 148 -27 0 -41 -7 -61 -31z m112 2 c7 0 11 -5 7
-10 -3 -5 -1 -12 5 -16 5 -3 10 -1 10 6 0 7 10 3 24 -10 31 -30 49 -91 36
-126 -15 -39 -31 -21 -24 26 6 36 3 44 -27 75 -18 19 -39 34 -47 34 -8 0 -10
5 -6 13 6 9 4 9 -8 -1 -9 -7 -25 -12 -35 -10 -13 2 -17 -2 -15 -16 2 -10 0
-16 -5 -13 -4 3 -8 -8 -8 -23 0 -15 4 -26 9 -23 4 3 5 -4 1 -16 -4 -12 -2 -21
4 -21 6 0 15 -10 22 -21 6 -12 7 -19 2 -16 -5 4 -10 0 -10 -7 0 -8 6 -12 12
-9 7 2 15 -6 19 -19 11 -43 6 -75 -16 -94 -14 -13 -19 -14 -14 -4 6 12 5 11
-6 -1 -8 -10 -24 -14 -43 -12 -38 5 -95 40 -95 59 0 8 -5 22 -11 32 -8 12 -9
28 -3 45 13 40 29 32 26 -13 -3 -34 1 -44 30 -72 30 -30 98 -52 83 -27 -4 5
-2 9 2 9 5 0 14 9 20 19 10 16 7 30 -20 83 -22 44 -32 77 -32 110 0 68 20 80
113 69z"/>
<path d="M4216 4057 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4800 4106 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M6567 4099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4850 4076 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M6490 4049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6438 4023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6356 3991 c2 -2 -4 -10 -14 -17 -12 -10 -4 -9 23 2 51 20 52 21 16
20 -16 -1 -27 -3 -25 -5z"/>
<path d="M5030 3982 c8 -5 26 -13 40 -16 17 -5 20 -4 10 2 -8 5 -26 13 -40 16
-17 5 -20 4 -10 -2z"/>
<path d="M6968 3936 c-10 -7 -18 -16 -18 -20 0 -4 23 -40 50 -81 28 -41 50
-77 50 -80 0 -3 -40 22 -89 56 -77 53 -91 60 -110 50 -26 -14 -25 -7 -1 -126
11 -54 19 -99 17 -101 -1 -2 -25 33 -52 76 -28 44 -55 80 -61 80 -7 0 -21 -8
-33 -17 l-21 -18 96 -147 c53 -82 101 -148 108 -148 6 0 22 9 36 20 l25 19
-26 128 c-15 70 -25 129 -24 131 1 2 51 -31 110 -73 60 -41 111 -75 114 -75 3
1 19 11 34 24 l29 23 -98 147 c-53 80 -102 146 -108 146 -6 0 -19 -7 -28 -14z
m38 -18 c5 -7 2 -9 -7 -3 -8 4 -2 -3 12 -16 35 -33 61 -77 53 -91 -4 -7 -3 -8
4 -4 7 4 12 1 12 -7 0 -7 12 -24 26 -38 32 -30 53 -65 32 -54 -7 3 -3 -2 10
-13 26 -23 28 -35 8 -51 -11 -9 -24 -5 -60 19 -25 17 -60 46 -78 63 -17 18
-28 25 -23 17 7 -13 6 -13 -11 0 -10 9 -35 24 -55 35 -26 13 -35 23 -32 36 4
15 8 15 41 -8 21 -14 36 -29 35 -34 -2 -4 3 -6 11 -3 7 3 23 -6 34 -21 11 -14
24 -24 29 -23 17 6 55 -30 47 -44 -6 -10 -4 -10 9 1 13 10 15 17 6 27 -6 8 -9
20 -6 29 4 8 1 17 -5 21 -7 4 -8 3 -4 -4 4 -7 3 -12 -1 -12 -5 0 -13 9 -18 19
-6 10 -32 49 -59 86 -39 53 -46 69 -35 76 18 11 17 11 25 -3z m-116 -116 c4
-85 5 -102 11 -102 3 0 11 -33 18 -72 7 -40 14 -80 16 -88 3 -8 -5 -26 -16
-40 -14 -17 -19 -19 -15 -7 3 10 2 15 -4 12 -10 -6 -92 114 -104 153 -4 12
-12 20 -17 17 -13 -9 -11 9 4 24 10 10 9 11 -5 6 -19 -8 -54 37 -43 55 11 19
36 -10 30 -34 -5 -15 -4 -17 3 -8 14 21 57 -42 61 -91 1 -5 5 -6 10 -2 12 6
25 -31 16 -45 -4 -6 1 -7 11 -3 14 5 16 3 10 -12 -4 -11 -4 -16 0 -12 4 4 14
1 22 -6 13 -10 14 -8 8 13 -4 14 -11 50 -16 80 -11 62 -14 75 -29 143 -12 52
-8 69 14 61 8 -3 14 -21 15 -42z"/>
<path d="M7004 3878 c-4 -7 -4 -10 1 -6 4 4 14 0 22 -10 13 -15 13 -15 3 6
-12 25 -16 27 -26 10z"/>
<path d="M6937 3779 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7116 3671 c-9 -15 23 -36 35 -24 8 8 6 11 -7 10 -11 -1 -18 4 -16
11 2 14 -4 16 -12 3z"/>
<path d="M5990 3900 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5310 3896 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M5438 3893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6055 3890 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5378 3883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6018 3883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5925 3870 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5978 3873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4424 3684 l-31 -185 21 -15 c11 -8 24 -14 27 -14 9 0 293 230 297
240 1 4 -9 15 -24 24 -25 17 -27 17 -65 -13 l-38 -31 -52 31 -51 32 6 48 c6
41 4 49 -13 58 -43 24 -45 18 -77 -175z m71 115 c-8 -46 0 -75 23 -83 35 -13
62 -33 54 -41 -4 -5 -2 -5 5 -2 16 10 44 -3 37 -17 -3 -6 6 4 21 22 15 18 31
29 37 26 6 -4 8 -2 4 4 -9 15 7 23 24 12 11 -7 12 -11 0 -25 -7 -9 -19 -14
-26 -11 -7 3 -16 0 -20 -6 -5 -7 -2 -8 7 -4 8 4 3 -2 -11 -14 -14 -11 -44 -37
-67 -57 -23 -19 -44 -34 -48 -33 -4 1 -11 -4 -16 -12 -5 -7 -19 -14 -31 -14
l-23 -1 24 15 c13 9 36 29 52 44 15 15 33 26 41 25 7 -2 10 -2 6 0 -4 2 -4 11
-1 19 3 8 -1 14 -9 14 -8 0 -24 9 -35 19 -12 11 -24 17 -29 13 -4 -4 -4 -1 0
6 5 8 0 12 -12 12 -21 0 -32 -18 -32 -51 0 -11 -3 -18 -7 -16 -5 3 -9 -19 -10
-49 -2 -51 3 -59 30 -55 15 2 6 -17 -15 -31 -33 -21 -48 -12 -48 28 0 20 4 33
8 30 4 -2 9 14 9 37 1 22 10 87 19 143 13 80 20 101 31 97 11 -4 13 -15 8 -44z
m30 -131 c14 -6 25 -14 25 -18 0 -11 -60 -64 -66 -57 -6 6 5 87 11 87 2 0 16
-5 30 -12z"/>
<path d="M5468 3863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5528 3863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5888 3863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6450 3653 c-8 -3 -52 -19 -97 -35 -84 -30 -93 -40 -70 -71 11 -16
17 -16 85 9 41 14 77 22 82 18 8 -10 32 -76 28 -79 -2 -1 -30 -11 -63 -22 -43
-14 -61 -25 -63 -39 -5 -40 10 -46 73 -24 32 11 63 20 70 20 13 0 37 -68 27
-74 -4 -2 -37 -13 -74 -25 -59 -20 -68 -26 -68 -47 0 -14 7 -27 15 -30 13 -6
173 46 205 66 13 8 9 27 -24 122 -80 233 -77 229 -126 211z m25 -23 c-17 -7
-17 -9 -3 -9 17 -1 43 -48 52 -95 3 -11 14 -46 26 -76 38 -98 41 -111 22 -108
-9 2 -22 19 -29 38 -16 43 -16 43 0 34 6 -4 0 4 -14 19 l-26 26 -51 -19 c-29
-11 -52 -17 -52 -14 0 4 -7 1 -15 -6 -10 -8 -15 -9 -15 -1 0 12 48 39 82 45
13 3 25 5 28 6 3 2 8 2 13 1 4 0 7 5 7 12 0 8 4 7 10 -3 6 -9 10 -10 10 -3 0
6 -5 15 -11 18 -6 4 -8 14 -5 22 3 8 2 12 -3 9 -9 -6 -33 63 -26 75 6 8 -47
-3 -87 -20 -15 -6 -35 -8 -45 -4 -10 4 -14 2 -10 -4 4 -6 -1 -13 -11 -15 -11
-3 -19 0 -19 8 0 14 84 47 98 38 5 -3 9 -1 9 5 0 5 12 14 28 19 34 13 65 14
37 2z m95 -300 c0 -7 -160 -63 -165 -58 -10 10 15 27 65 45 51 19 100 26 100
13z"/>
<path d="M4981 3598 c-5 -13 -26 -76 -47 -141 l-38 -119 -30 7 c-64 15 -81 12
-84 -16 -3 -24 2 -27 125 -66 133 -42 157 -42 151 0 -2 14 -17 25 -45 35 -24
7 -43 18 -43 24 0 6 19 69 42 140 25 77 38 132 32 137 -5 5 -19 12 -31 16 -18
5 -25 1 -32 -17z m46 -22 c-3 -7 -13 -40 -22 -75 -14 -47 -22 -61 -33 -56 -12
4 -12 3 -3 -9 9 -11 9 -22 -3 -49 -8 -20 -12 -38 -8 -41 3 -3 0 -6 -7 -6 -7 0
-9 -5 -5 -11 3 -6 0 -15 -7 -20 -11 -7 -10 -9 4 -9 9 0 17 -8 17 -17 0 -13 2
-15 8 -5 6 9 16 10 34 3 29 -11 46 -31 26 -31 -29 0 -88 22 -83 30 3 6 -1 7
-9 4 -8 -3 -25 0 -38 7 -13 7 -27 12 -32 11 -17 -5 -67 21 -58 30 10 10 73
-10 84 -26 4 -6 9 -4 14 7 3 9 11 14 17 10 5 -3 7 -1 4 4 -7 11 30 144 42 153
3 3 7 16 9 30 1 13 7 27 13 30 5 4 9 11 8 16 -5 27 2 43 17 37 8 -3 13 -11 11
-17z"/>
<path d="M5976 3529 c-54 -9 -88 -29 -120 -73 -31 -41 -29 -156 3 -212 35 -61
71 -79 158 -77 116 3 113 -2 92 155 -33 241 -27 218 -57 217 -15 -1 -49 -5
-76 -10z m89 -31 c8 -34 35 -249 35 -278 0 -23 -5 -26 -36 -28 -20 -2 -41 -4
-46 -5 -6 -1 -13 4 -16 12 -3 10 1 13 16 9 12 -3 28 -1 37 6 13 9 14 21 6 81
-21 149 -22 178 -9 186 6 4 -10 7 -38 5 -57 -2 -110 -27 -119 -56 -4 -11 -9
-18 -13 -16 -10 6 -6 -53 7 -101 6 -23 11 -45 10 -50 -1 -4 -1 -15 0 -23 1
-12 3 -12 9 -2 7 9 13 8 27 -8 10 -11 26 -20 35 -20 10 0 20 -6 23 -14 4 -10
0 -14 -13 -13 -10 1 -16 5 -13 11 4 5 -1 7 -9 3 -22 -8 -77 33 -84 63 0 3 -4
13 -8 23 -5 9 -5 24 -2 33 3 8 2 13 -3 10 -16 -10 -4 94 13 113 9 10 13 21 9
25 -4 4 1 4 11 0 13 -5 17 -4 12 4 -9 14 24 32 71 38 20 2 39 7 42 9 13 13 41
3 46 -17z m-35 -130 c6 -57 12 -110 13 -118 2 -11 -10 -16 -40 -18 -58 -4 -88
26 -98 97 -9 66 7 109 49 127 61 26 63 24 76 -88z"/>
<path d="M6028 3503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5920 3480 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5317 3523 c-2 -4 19 -89 47 -188 49 -174 52 -180 78 -183 26 -3 31
5 113 168 48 95 84 174 82 176 -2 2 -18 7 -35 10 -30 6 -33 4 -51 -38 l-19
-45 -54 4 c-74 6 -71 5 -83 54 -10 39 -14 44 -42 47 -17 2 -33 -1 -36 -5z m53
-22 c0 -5 -6 -12 -12 -14 -8 -3 -5 -6 6 -6 12 -1 16 -6 12 -16 -3 -8 -1 -15 5
-15 6 0 8 -4 5 -9 -11 -17 20 -29 84 -31 96 -3 100 -2 94 18 -5 15 -4 15 6 2
9 -12 11 -10 6 12 -5 21 -1 30 14 38 28 15 26 10 -58 -162 -43 -88 -78 -148
-87 -148 -8 0 -19 17 -25 43 -7 23 -21 73 -32 111 -17 57 -18 70 -7 77 11 7
10 9 -5 9 -11 0 -16 3 -13 7 4 3 3 11 -2 17 -5 6 -12 26 -16 44 -5 25 -3 32 9
32 9 0 16 -4 16 -9z"/>
<path d="M5505 3390 c15 -8 6 -9 -33 -5 -29 3 -57 3 -61 0 -13 -8 25 -165 39
-165 4 0 13 13 20 30 7 16 16 27 20 25 5 -3 12 0 16 6 4 8 3 9 -4 5 -15 -9
-15 -3 0 29 7 15 14 21 18 14 4 -6 7 7 6 28 -1 30 -5 38 -21 40 l-20 3 20 -10z
m-24 -28 c14 -6 14 -12 -4 -51 -21 -49 -30 -44 -42 22 -6 32 -5 37 11 36 11 0
26 -3 35 -7z"/>
<path d="M5476 3245 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
